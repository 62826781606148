<template>
  <div class="homepage">
    <div class="contact-details">
      <h1 class="fade-in">
        <span class="fade-in-1">Kirsty Whittaker</span><br /><span
          class="fade-in-2"
          >Freelance Designer</span
        ><br /><span class="fade-in-3">07903 836461</span><br /><a
          href="mailto:kirstywhittaker@hotmail.com"
          ><span class="fade-in-4">Email</span></a
        >
      </h1>

      <dl class="fade-in-5">
        <dt>20–12</dt>
        <dd>NB Studio</dd>
      </dl>
      <dl class="fade-in-6">
        <dt>12–11</dt>
        <dd>Hat-trick Design</dd>
      </dl>
      <dl class="fade-in-7">
        <dt>11–07</dt>
        <dd>Pentagram</dd>
      </dl>
    </div>
    <div class="background-image fade-in-0">
      <img
        v-if="isPortrait"
        srcset="
          ../assets/Kirsty_Whittaker_Freelance_Designer_Mobile@0.25x.jpg 512w
          ../assets/Kirsty_Whittaker_Freelance_Designer_Mobile@0.5x.jpg 1024w
          ../assets/Kirsty_Whittaker_Freelance_Designer_Mobile.jpg 2048w,
          ../assets/Kirsty_Whittaker_Freelance_Designer_Mobile@2x.jpg 4096w 
        "
        src="../assets/Kirsty_Whittaker_Freelance_Designer_Mobile@0.25x.jpg"
        alt="Kirsty Whittaker Freelance Designer"
      />
      <img
        v-else
        srcset="
          ../assets/Kirsty_Whittaker_Freelance_Designer_Desktop@0.25x.jpg  700w,
          ../assets/Kirsty_Whittaker_Freelance_Designer_Desktop@0.5x.jpg  1400w,
          ../assets/Kirsty_Whittaker_Freelance_Designer_Desktop.jpg       2800w,
          ../assets/Kirsty_Whittaker_Freelance_Designer_Desktop@2x.jpg    5600w
        "
        src="../assets/Kirsty_Whittaker_Freelance_Designer_Desktop@0.25x.jpg"
        alt="Kirsty Whittaker Freelance Designer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      isPortrait: false,
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.onResizeHandler();
      window.addEventListener("resize", this.onResizeHandler);
    });
  },
  methods: {
    onResizeHandler() {
      console.log("on resize");
      if (window.innerHeight > window.innerWidth) {
        this.isPortrait = true;
      } else {
        this.isPortrait = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes fadeInTranstion {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
html {
  padding: 0;
  margin: 0;
  font-size: 62.5%;
  background: black;
}
body {
  background: black;
}
@font-face {
  font-family: "akzidenz-grotesk_bqBdEx";
  src: url("../assets/fonts/akzidenzgrotesk-boldextended-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/akzidenzgrotesk-boldextended-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
h1 {
  font-family: "akzidenz-grotesk_bqBdEx";
  // font-size: 3rem;
  font-size: calc(26px + (60 - 26) * (100vw - 400px) / (1800-400));
  line-height: calc(26px + (60 - 26) * (100vw - 400px) / (1800-400));
  @media screen and (min-width: 1800px) {
    font-size: 60px;
    line-height: 60px;
  }
  color: white;
  padding: 0;
  margin: 0 0 2rem 0;
  a {
    text-decoration: none;
    color: white;
    border-bottom: 4px solid rgba(255, 255, 255, 0);
    transition: border 0.5s ease-in-out;
    &:hover {
      border-bottom: 4px solid white;
    }
  }
}
dl {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 4px 0;
  dt {
    font-family: "akzidenz-grotesk_bqBdEx";
    color: white;
    font-size: calc(10px + (14 - 10) * (100vw - 400px) / (1800-400));
    width: calc(50px + (80 - 50) * (100vw - 400px) / (1800-400));
    @media screen and (min-width: 1800px) {
      font-size: 14px;
    }
    padding: 0;
    margin: 0;
  }
  dd {
    font-family: "akzidenz-grotesk_bqBdEx";
    color: white;
    font-size: calc(18px + (28 - 18) * (100vw - 400px) / (1800-400));
    @media screen and (min-width: 1800px) {
      font-size: 28px;
    }
    padding: 0;
    margin: 0;
    line-height: 1;
  }
}
.contact-details {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 1rem;
  @media screen and (min-width: 1024px) {
    padding: 2rem;
  }
}
.background-image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.fade-in-0 {
  opacity: 0;
  animation: slidein 1s 0s ease-in-out forwards;
}
.fade-in-1 {
  opacity: 0;
  animation: slidein 1s 0s ease-in forwards;
}
.fade-in-2 {
  opacity: 0;
  animation: slidein 1s 1s ease-in forwards;
}
.fade-in-3 {
  opacity: 0;
  animation: slidein 1s 2s ease-in forwards;
}
.fade-in-4 {
  opacity: 0;
  animation: slidein 1s 2s ease-in forwards;
}
.fade-in-5 {
  opacity: 0;
  animation: slidein 1s 3.5s ease-in forwards;
}
.fade-in-6 {
  opacity: 0;
  animation: slidein 1s 3.8s ease-in forwards;
}
.fade-in-7 {
  opacity: 0;
  animation: slidein 1s 4.1s ease-in forwards;
}
@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
